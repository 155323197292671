import initTrueGlobal from './true-global.js';
import initCollapse from "./components/collapse-text";
import {initMultiPageContainer} from "./components/multi-page-container";
import {hasClass} from "./components/helpers";
import {initNewsletterSignupForm} from "./components/common/init-newsletter-signup-form";

function initLandingPage() {
	console.log('this da landingpage');
	initCollapse();

	const body = document.querySelector('body#top');

	if (hasClass(body, 'pagetype--home')) {
		const helpdeskLinks = document.querySelectorAll('a[href$="service/kundeninformationen/telefonberatung.html"]')
		helpdeskLinks.forEach(link => {
			link.addEventListener('click', e => {
				e.preventDefault();
				if(typeof zE !== "undefined") {
					zE(function() {zE.activate();});
				}
			})
		})
	}
}
initNewsletterSignupForm();

initMultiPageContainer();
initTrueGlobal();
initLandingPage();

(function(){
	console.log('this da landingpage');
})();
