"use strict";

import {addClass, hasClass, removeClass} from "./helpers";
import initCollapse from "./collapse-text";
import {initAccordions} from "./accordions";
import bindScrollLinks from "./scroll-links";
import {initImageSlideshows} from "./image-slideshows";
import {initTeaserSlideshows} from "./teaser-slideshows";
import {initProductSliders} from "./product-slider";
import {initTables} from "./tables";

const defaults = {
    selectors: {
        html: 'html:root',
        body: 'body#top',
        container: '.js-multi-page-container',
        pageSection: '.js-page-section',
        pageSectionContent: '.js-page-section-content',
        dataContainer: '.js-page-sections-data',
        navigation: '.js-multi-page-navigation',
        navigationList: '.js-nav-list',
        arrowsContainer: '.js-nav-arrows',
        arrowPrev: '.js-nav-arrow-prev',
        arrowNext: '.js-nav-arrow-next',
        arrowToggleCollapse: '.js-nav-arrow-toggle-collapse',
    }
}

const state = {
    html: null,
    body: null,
    container: null,
    pageSections: null,
    dataContainer: null,
    pagesData: null,
    navigation: null,
    navigationList: null,
    navArrows: {
        container: null,
        prev: null,
        next: null,
        toggleCollapse: null,
    },
    scrollPos: null,
    baseScrollPos: null,
}

export function initMultiPageContainer() {
    state.html = document.querySelector(defaults.selectors.html);
    state.body = document.querySelector(defaults.selectors.body);
    state.container = document.querySelector(defaults.selectors.container);
    if(!state.container) {
        return false;
    }

    state.ob = new IntersectionObserver(handleSectionIntersection, {threshold: [0, 0.5]});
    state.navigation = state.container.querySelector(defaults.selectors.navigation);
    state.navigationList = state.navigation.querySelector(defaults.selectors.navigationList);
    state.navArrows.container = state.navigation.querySelector(defaults.selectors.arrowsContainer);
    state.navArrows.prev = state.navigation.querySelector(defaults.selectors.arrowPrev);
    state.navArrows.next = state.navigation.querySelector(defaults.selectors.arrowNext);
    state.navArrows.toggleCollapse = state.navigation.querySelector(defaults.selectors.arrowToggleCollapse);
    state.pageSections = state.container.querySelectorAll(defaults.selectors.pageSection);
    state.dataContainer = state.container.querySelector(defaults.selectors.dataContainer);
    state.pagesData = collectSections();

    initPagePosition();

    buildSectionNav();
    bindNavArrows();

    setTimeout(function () {
        state.pagesData.forEach(section => {
            state.ob.observe(section.el);
            addClass(section.el, 'is-observed');
        });
        state.baseScrollPos = window.scrollY;

        window.addEventListener('scroll', handleScroll);
    }, 1000);

    console.log(state.container);
}

function handleScroll() {
    if(window.scrollY > state.baseScrollPos + 50 || window.scrollY < state.baseScrollPos - 50) {
        if(!hasClass(state.navigation, 'is-collapsed')) {
            addClass(state.navigation, 'is-collapsed');
        }
        window.removeEventListener('scroll', handleScroll, false);
    }
}

function initPagePosition() {

    let pathname = window.location.pathname;

    let currentSection = Array.from(state.pageSections).find(section => {
        return section.dataset.url === pathname;
    });

    console.log(currentSection);

    if(currentSection) {
        setTimeout(function () {
            currentSection.scrollIntoView({block: 'start' });
        },2);
    }
}

function handleSectionIntersection(payload) {
    console.log(payload);
    payload.forEach(entry => {
        let sectionId = parseInt(entry.target.dataset.id);

        if(entry.isIntersecting) {
            insertSection(sectionId);

            if(entry.intersectionRatio > 0.5) {
                activateSection(sectionId);
            }
        }
    })
}

function collectSections() {

    let sectionData = JSON.parse(state.dataContainer.innerText);

    sectionData = sectionData.map(section => {
        section.el = state.container.querySelector(`[data-url="${section.url}"]`);
        section.inserted = !!section.isCurrent;

        return section;

    });

    return sectionData;
}

function insertSection(sectionId) {

    let targetSection = state.pagesData.find(section => {
        return section.sbcsId === sectionId;
    });

    if(!targetSection) {
        return false;
    }

    let sectionContent = targetSection.el.querySelector(defaults.selectors.pageSectionContent);

    if(!sectionContent) {
        return false;
    }

    // dont insert if already inserted
    if(sectionContent.innerHTML.trim().length > 0 || targetSection.inserted) {
        return false;
    }

    sectionContent.innerHTML = targetSection.content;
    addClass(targetSection.el, 'is-inserted');


    let currentSection = state.pagesData.find(section => {
        return section.isCurrent;
    });

    // insert sections in between current and target section when using the navigation
    let currentSectionIndex = state.pagesData.indexOf(currentSection);
    let targetSectionIndex = state.pagesData.indexOf(targetSection);
    let distance = targetSectionIndex - currentSectionIndex;

    // positive distance means target is after current
    if(distance > 0) {

    } else if(distance < 0) {
        console.log(sectionContent.offsetHeight);
        let offset = sectionContent.offsetHeight > window.innerHeight ? sectionContent.offsetHeight : window.innerHeight;
        window.scrollBy({
            top: offset, // could be negative value
            left: 0,
        });
    }

    bindScrollLinks(sectionContent);
    initImageSlideshows(sectionContent);
    initTeaserSlideshows(sectionContent);
    initProductSliders(sectionContent);
    initTables(sectionContent);
    initAccordions(sectionContent);
    initCollapse(sectionContent);
}

function lockScrollPosition() {
    console.log("lock scroll position");
    state.html.style.scrollbarGutter = "stable";
    state.body.style.overflow = 'hidden';
    state.scrollPos = window.scrollY;
}

function unlockScrollPosition() {
    window.scrollY = state.scrollPos;
    state.html.style.scrollbarGutter = "stable";
    state.body.style.overflow = 'auto';
}

function buildSectionNav() {
    state.navigationList.innerHTML = '';

    state.pagesData.forEach(section => {
        let navItem = document.createElement('li');
        addClass(navItem, 'nav-item');
        if(section.isCurrent) {
            addClass(navItem, 'is-active');
        }
        navItem.innerHTML = `<a href="${section.url}" class="nav-link">${section.title}</a>`;
        state.navigationList.appendChild(navItem);

        navItem.addEventListener('click', function (e) {
            e.preventDefault();
            goToSection(section.sbcsId);
        })
    });
}

function bindNavArrows() {
    state.navArrows.prev.addEventListener('click', function (e) {
        e.preventDefault();
        goToPrevSection();
    });

    state.navArrows.next.addEventListener('click', function (e) {
        e.preventDefault();
        goToNextSection();
    });

    state.navArrows.toggleCollapse.addEventListener('click', function (e) {
        e.preventDefault();
        toggleCollapse();
    });
}

function toggleCollapse() {
    console.log("collapse");
    if(hasClass(state.navigation, 'is-collapsed')) {
        removeClass(state.navigation,'is-collapsed');
    } else {
        addClass(state.navigation, 'is-collapsed');
    }
}

function goToPrevSection() {
    let currentSection = getCurrentSection();
    let currentSectionIndex = state.pagesData.indexOf(currentSection);

    if(currentSectionIndex === 0) {
        return false;
    }

    let prevSection = state.pagesData[currentSectionIndex - 1];

    goToSection(prevSection.sbcsId);
}

function goToNextSection() {
    let currentSection = getCurrentSection();
    let currentSectionIndex = state.pagesData.indexOf(currentSection);

    if(currentSectionIndex === state.pagesData.length - 1) {
        return false;
    }

    let nextSection = state.pagesData[currentSectionIndex + 1];

    goToSection(nextSection.sbcsId);
}

function updateNavArrows() {
    let currentSection = getCurrentSection();
    let currentSectionIndex = state.pagesData.indexOf(currentSection);
    removeClass(state.navArrows.prev, 'is-disabled');
    removeClass(state.navArrows.next, 'is-disabled');

    if(currentSectionIndex === 0) {
        addClass(state.navArrows.prev, 'is-disabled');
    } else if (currentSectionIndex === state.pagesData.length - 1) {
        addClass(state.navArrows.next, 'is-disabled');
    }
}

function updateSectionNav(sectionId) {
    let currentSection = getCurrentSection();
    let targetSection = getSectionById(sectionId);

    let navItems = state.navigation.querySelectorAll('.nav-item');
    navItems.forEach(item => {
        removeClass(item, 'is-active');
    });

    let targetNavItem = state.navigation.querySelector(`[href="${targetSection.url}"]`).parentNode;
    addClass(targetNavItem, 'is-active');
}

function goToSection(sectionId) {
    let targetSection = getSectionById(sectionId);

    if(!targetSection) {
        return false;
    }

    let currentSection = getCurrentSection();

    if(currentSection === targetSection) {
        console.log("same section");
        return false;
    }

    // insert sections in between current and target section when using the navigation
    let sectionsBetween = [];
    let currentSectionIndex = state.pagesData.indexOf(currentSection);
    let targetSectionIndex = state.pagesData.indexOf(targetSection);
    let distance = targetSectionIndex - currentSectionIndex;

    // positive distance means target is after current
    if(distance > 1) {
        for (let i = 1; i < distance; i++) {
            let section = state.pagesData[currentSectionIndex + i];
            sectionsBetween.push(section.sbcsId);
        }
    } else if(distance < -1) {
        for (let i = -1; i > distance; i--) {
            let section = state.pagesData[currentSectionIndex + i];
            sectionsBetween.push(section.sbcsId);
        }
    }

    if (sectionsBetween.length > 0) {
        sectionsBetween.forEach(sectionId => {
            insertSection(sectionId);
        });
    }

    if(!targetSection.inserted) {
        insertSection(sectionId);
    }

    targetSection.el.scrollIntoView({ behavior: 'smooth', block: 'start' });

    activateSection(sectionId);
}

function activateSection(sectionId) {
    let targetSection = getSectionById(sectionId);
    let currentSection = getCurrentSection();

    currentSection.isCurrent = false;
    targetSection.isCurrent = true;
    history.pushState({}, '', targetSection.url);
    updateSectionNav(sectionId);
    updateNavArrows();
}

function getSectionById(sectionId) {
    return state.pagesData.find(section => {
        return section.sbcsId === sectionId;
    });
}
function getCurrentSection() {
    return state.pagesData.find(section => {
        return section.isCurrent;
    });
}
