"use strict";

import { lang } from '../helpers'

import Vue from 'vue';
import NewsletterSignupForm from './NewsletterSignupForm.vue';
import VueI18n from "vue-i18n";
Vue.use(VueI18n);

import store from './../../store';

const defaults = {
    selectors: {
        container: '.js-newsletter-signup-form',

    }
};

export function initNewsletterSignupForm () {

    console.log("newsletter signup form");

    if (!document.querySelector(defaults.selectors.container)) {
        return false;
    }

    // const searchWordFromEl = document.querySelector(defaults.selectors.searchForm).dataset.searchWord;

    const messages = {
        de: require('../../../lang/lang_german'),
        en: require('../../../lang/lang_english'),
    }

    const i18n = new VueI18n({
        locale: lang, // set locale
        messages, // set locale messages
    })


    let registerForm = new Vue({
        el: '.js-newsletter-signup-form',
        store,
        i18n,
        template: '<newsletter-signup-form></newsletter-signup-form>',
        components: { NewsletterSignupForm },
    });
}
