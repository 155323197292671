var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "newsletterSignupForm",
      staticClass: "newsletter-signup-form",
      attrs: { id: "newsletter-signup-form" },
    },
    [
      _vm.errors.length > 0
        ? _c("div", { staticClass: "errors" }, [
            _c(
              "div",
              { staticClass: "notification is-warning" },
              [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.$t("messages.errors.formError")) +
                    "\n            "
                ),
                _vm._l(_vm.errors, function (error, index) {
                  return _c("span", [
                    index !== 0 ? _c("span", [_vm._v(", ")]) : _vm._e(),
                    _vm._v(_vm._s(error)),
                  ])
                }),
              ],
              2
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.successful_signup
        ? _c("div", { staticClass: "success-message" }, [
            _c("div", { staticClass: "notification" }, [
              _c("div", { staticClass: "text content-text content" }, [
                _c("h3", { staticClass: "text__headline" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t("components.newsletter_form.confirmation_headline")
                    )
                  ),
                ]),
                _vm._v(" "),
                _c("div", {
                  staticClass: "text",
                  domProps: {
                    innerHTML: _vm._s(
                      _vm.$t("components.newsletter_form.confirmation_text")
                    ),
                  },
                }),
              ]),
            ]),
          ])
        : _c("div", { staticClass: "form" }, [
            _c("div", { staticClass: "form__fieldgroup-row" }, [
              _c(
                "div",
                {
                  staticClass:
                    "signup-form__fieldgroup form__fieldgroup is-half",
                },
                [
                  _c(
                    "label",
                    {
                      staticClass: "signup-form__label form__label",
                      attrs: { for: "signup-form__gender" },
                    },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.$t("field_labels.gender")) +
                          "\n                "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.customerData.gender,
                          expression: "customerData.gender",
                        },
                      ],
                      staticClass:
                        "signup-form__input form__select form__input select-input text-input u-full-width",
                      attrs: { name: "gender", id: "signup-form__gender" },
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.customerData,
                            "gender",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                      },
                    },
                    [
                      _c("option", { attrs: { value: "" } }),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "f" } }, [
                        _vm._v(_vm._s(_vm.$t("field_labels.female"))),
                      ]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "m" } }, [
                        _vm._v(_vm._s(_vm.$t("field_labels.male"))),
                      ]),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "signup-form__fieldgroup form__fieldgroup" },
                [
                  _c(
                    "label",
                    {
                      staticClass: "signup-form__label form__label",
                      attrs: { for: "signup-form__first-name" },
                    },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.$t("field_labels.first_name")) +
                          " "
                      ),
                      _c("span", { staticClass: "marker--required" }, [
                        _vm._v("*"),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.customerData.firstname,
                        expression: "customerData.firstname",
                      },
                    ],
                    staticClass:
                      "signup-form__input form__input text-input u-full-width",
                    attrs: {
                      type: "text",
                      placeholder: _vm.$t("field_labels.first_name"),
                      id: "signup-form__first-name",
                      name: "firstname",
                    },
                    domProps: { value: _vm.customerData.firstname },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.customerData,
                          "firstname",
                          $event.target.value
                        )
                      },
                    },
                  }),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "signup-form__fieldgroup form__fieldgroup" },
                [
                  _c(
                    "label",
                    {
                      staticClass: "signup-form__label form__label",
                      attrs: { for: "signup-form__last-name" },
                    },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.$t("field_labels.last_name")) +
                          "\n                "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.customerData.lastname,
                        expression: "customerData.lastname",
                      },
                    ],
                    staticClass:
                      "signup-form__input form__input text-input u-full-width",
                    attrs: {
                      type: "text",
                      placeholder: _vm.$t("field_labels.last_name"),
                      id: "signup-form__last-name",
                      name: "Address lastname",
                    },
                    domProps: { value: _vm.customerData.lastname },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.customerData,
                          "lastname",
                          $event.target.value
                        )
                      },
                    },
                  }),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form__fieldgroup-row" }, [
              _c(
                "div",
                { staticClass: "signup-form__fieldgroup form__fieldgroup" },
                [
                  _c(
                    "label",
                    {
                      staticClass: "signup-form__label form__label",
                      attrs: { for: "signup-form__email" },
                    },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.$t("field_labels.email")) +
                          "  "
                      ),
                      _c("span", { staticClass: "marker--required" }, [
                        _vm._v("*"),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.customerData.email,
                        expression: "customerData.email",
                      },
                    ],
                    staticClass:
                      "signup-form__input form__input text-input u-full-width",
                    attrs: {
                      type: "email",
                      placeholder: _vm.$t("field_labels.email"),
                      id: "signup-form__email",
                      name: "email",
                      required: "",
                    },
                    domProps: { value: _vm.customerData.email },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.customerData, "email", $event.target.value)
                      },
                    },
                  }),
                ]
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "signup-form__fieldgroup form__fieldgroup u-no-margin-bottom",
              },
              [
                _c(
                  "button",
                  {
                    staticClass: "button main-action-button",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.signup()
                      },
                    },
                  },
                  [
                    _vm._v(
                      _vm._s(_vm.$t("components.signup_form.button_signup"))
                    ),
                  ]
                ),
              ]
            ),
          ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }