<template>
    <div id="newsletter-signup-form" class="newsletter-signup-form" ref="newsletterSignupForm">

        <div class="errors" v-if="errors.length > 0">
            <div class="notification is-warning">
                {{ $t("messages.errors.formError") }}
                <span v-for="(error, index) in errors"><span v-if="index !== 0">, </span>{{ error }}</span>
            </div>
        </div>


        <div class="success-message " v-if="successful_signup">
            <div class="notification">

                <div class="text content-text content">
                    <h3 class="text__headline">{{ $t("components.newsletter_form.confirmation_headline") }}</h3>
                    <div class="text" v-html="$t('components.newsletter_form.confirmation_text')"></div>
                </div>

            </div>
        </div>

        <div class="form" v-else>
            <div class="form__fieldgroup-row">

                <div class="signup-form__fieldgroup form__fieldgroup is-half">
                    <label class="signup-form__label form__label" for="signup-form__gender">
                        {{ $t("field_labels.gender") }}
                    </label>
                    <select name="gender"
                            id="signup-form__gender"
                            class="signup-form__input form__select form__input select-input text-input u-full-width"
                            v-model="customerData.gender">
                        <option value=""> </option>
                        <option value="f">{{ $t("field_labels.female") }}</option>
                        <option value="m">{{ $t("field_labels.male") }}</option>
                    </select>

                </div>

                <div class="signup-form__fieldgroup form__fieldgroup">
                    <label class="signup-form__label form__label" for="signup-form__first-name">
                        {{ $t("field_labels.first_name") }} <span class="marker--required">*</span>
                    </label>
                    <input type="text"
                           :placeholder='$t("field_labels.first_name")'
                           class="signup-form__input form__input text-input u-full-width"
                           id="signup-form__first-name"
                           name="firstname"
                           v-model="customerData.firstname"/>
                </div>

                <div class="signup-form__fieldgroup form__fieldgroup">
                    <label class="signup-form__label form__label" for="signup-form__last-name">
                        {{ $t("field_labels.last_name") }}
                    </label>
                    <input type="text"
                           :placeholder='$t("field_labels.last_name")'
                           class="signup-form__input form__input text-input u-full-width"
                           id="signup-form__last-name"
                           name="Address lastname"
                           v-model="customerData.lastname"/>
                </div>
            </div>




            <div class="form__fieldgroup-row">
                <div class="signup-form__fieldgroup form__fieldgroup">
                    <label class="signup-form__label form__label" for="signup-form__email">
                        {{ $t("field_labels.email") }}  <span class="marker--required">*</span>
                    </label>
                    <input type="email"
                           :placeholder='$t("field_labels.email")'
                           class="signup-form__input form__input text-input u-full-width"
                           id="signup-form__email"
                           name="email"
                           v-model="customerData.email"
                           required/>
                </div>


            </div>


            <div class="signup-form__fieldgroup form__fieldgroup u-no-margin-bottom">
                <button @click.prevent="signup()" type="button" class="button main-action-button " >{{ $t("components.signup_form.button_signup") }}</button>
            </div>
        </div>



    </div>
</template>

<script>
import axios from "axios";
import {lang, validateEmail} from "../helpers";

export default {
    name: 'NewsletterSignupForm',
    props: {
    },

    data() {
        return {
            customerData: {},
            errors: [],
            successful_signup: false,
        }
    },

    methods: {

        prepareMaxLength(format_display) {
            return format_display.length
        },

        cancelEdit() {
            // reset to cached version
            for (const field in this.addressDataCache) {
                if(this.addressDataCache.hasOwnProperty(field) && this.customerData.hasOwnProperty(field) ) {
                    this.addressData[field] = this.addressDataCache[field];
                }
            }

            this.customerData.editMode = false;
        },

        signup() {
            if(this.checkCustomerDataValid()) {
                let data = JSON.parse(JSON.stringify(this.customerData));
                data.userSource  = "newsletter_form"

                console.log(data);
                // return;
                this.$store.dispatch('newsletterSignupForm/signup', data).then((response) => {
                    this.successful_signup = true;
                    this.errors = [];
                    if(response.hasOwnProperty('redirect')) {
                        this.redirectToSuccessPage(response.redirect);
                    }
                }).catch(error => {
                    if(error.hasOwnProperty('errors')) {
                        this.errors = error.errors;
                    } else {
                        if(error.hasOwnProperty('error')) {
                            this.errors.push(error.error);
                        } else {
                            this.errors.push(error);
                        }
                    }
                    this.$refs.newsletterSignupForm.scrollIntoView({ behavior: 'smooth' });
                })
            }

        },

        redirectToSuccessPage(pageurl) {
            console.log(pageurl);
            window.location = pageurl;
        },

        checkCustomerDataValid() {
            let isValid = true;
            let errors = [];

            // if(!this.customerData.gender || this.customerData.gender === "") {
            //     errors.push(this.$t("form_validation.missing_gender"));
            //     isValid = false;
            // }
            //
            if(!this.customerData.firstname || this.customerData.firstname === "") {
                errors.push(this.$t("form_validation.missing_firstname_fieldname"));
                isValid = false;
            }

            // if(!this.customerData.lastName || this.customerData.lastName === "") {
            //     errors.push(this.$t("form_validation.missing_lastname"));
            //     isValid = false;
            // }

            if(!this.customerData.email || this.customerData.email === "") {
                errors.push(this.$t("form_validation.missing_email_fieldname"));
                isValid = false;
            } else if(!validateEmail(this.customerData.email)) {
                errors.push(this.$t("form_validation.wrong_email"));
                isValid = false;
            }


            if(!isValid) {
                this.errors = errors;
                this.$refs.newsletterSignupForm.scrollIntoView({ behavior: 'smooth' });
                return false;
            } else {
                return true;
            }
        }

    },
    created() {

    },
    watch: {

    },
    computed: {
    }
}
</script>
