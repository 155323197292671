"use strict";

require('smoothscroll-polyfill').polyfill();
import {addClass, removeClass, toggleClass, hasClass} from "./helpers";

const defaults = {
    selectors: {
        collapseText: '.js-collapse-text',
        collapseTextInner: '.js-collapse-text-inner',
    },
    settings: {
        toggleClass: 'js-toggle-collapse-text',
        toggleStyleClass: 'toggle-collapse-text',
        hasMoreClass: 'has-more-text',
        openClass: 'is-open',
    }
};

export default function initCollapse(parentNode = document) {
    let allCollapses = parentNode.querySelectorAll(defaults.selectors.collapseText);

    console.log(allCollapses);

    if(!allCollapses) {
        return false;
    }

    Array.from(allCollapses).forEach(collapseEl => {
        let inner = collapseEl.querySelector(defaults.selectors.collapseTextInner);

        if(collapseEl.clientHeight >= inner.clientHeight) {
            return false;
        }

        let readMoreText = collapseEl.dataset['readMoreText'];
        let readLessText = collapseEl.dataset['readLessText'];
        let scrollOnClose = collapseEl.dataset['scrollOnClose'] !== "false";

        addClass(collapseEl, 'has-more-text');

        let textToggle = document.createElement("button");
        textToggle.dataset.text = readMoreText;
        addClass(textToggle, defaults.settings.toggleClass);
        addClass(textToggle, defaults.settings.toggleStyleClass);

        collapseEl.appendChild(textToggle);

        textToggle.addEventListener('click', function (event) {
            event.preventDefault();

            if (hasClass(collapseEl, defaults.settings.openClass)) {
                textToggle.dataset.text = readMoreText;
                if(scrollOnClose) {
                    collapseEl.scrollIntoView({ behavior: 'smooth', block: 'start' });
                }
                removeClass(collapseEl, defaults.settings.openClass);
            } else {
                textToggle.dataset.text = readLessText;
                addClass(collapseEl, defaults.settings.openClass);
            }
        })
    })
}
